import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Card = props => {
  const { titre, slug, prix, categorie, reference, image } = props

  return (
    <div className="bg-white h-full shadow-sm rounded-md overflow-hidden group">
      <Link to={`/${categorie}/${slug}`}>
        <div className="group-hover:opacity-75 transition duration-150 ease-in-out"
        
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
        >
          
        {image && image.localFile && image.localFile.childImageSharp && (   
          <Img
          style={{width: "100%"}}
          // style={{ height: "100%", width: "100%" }}
          // imgStyle={{objectFit: 'contain'}}
          imgStyle={{ objectFit: 'contain' }}
          // fluid={ image.localFile.childImageSharp.fluid }
          fixed={image.localFile.childImageSharp.fixed}
          alt={titre} />
        )}
        </div>
        <div className="p-4 sm:p-5">
          <h1 className="sm:text-lg text-gray-900 font-semibold">{titre}</h1>
         

         
      <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <div className="w-max inline-flex items-center">
              
              <p className="text-sm sm:text-base text-gray-700">{prix} €</p>
            </div>
          </div>
          <div className="w-max text-gray-500 italic">
            {reference}
          </div>
        </div>


      </div>

      
      </Link>
    </div>
  )
}

Card.propTypes = {
  titre: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  prix: PropTypes.string.isRequired,
  categorie: PropTypes.array.isRequired,
  reference: PropTypes.string.isRequired,
  image: PropTypes.shape({
    localFile: PropTypes.object,
  }),
}

export default Card

export const query = graphql`
fragment ArticlesCard on ContentfulArticles {
  titre
  prix
  slug
  categorie
  reference
  image {
    localFile {
      childImageSharp {
        fluid(maxWidth: 444, maxHeight: 342, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(height: 300, quality: 85) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
}
`
